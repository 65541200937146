import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { map } from 'lodash/fp'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { intlMessageForId } from 'localization'
import {
  getDateRanges,
  isValidDateRange,
  toIsoDate,
  toIsoDateTime,
  toLocaleDate,
} from 'utils/time'
import { EventAction, EventCategory, trackEvent } from 'utils/analytics'
import { deconstructFormElementRef } from 'utils/ref'
import { AnyObject, ISODate, ISODateTime } from 'types'
import { DateRange, LayoutDirection } from 'types/enums'
import Color from 'types/color'
import { SmallPrimaryButton, SmallSecondaryButton } from 'components/Button'
import BaseSelect from 'components/Select'
import SocialDistancing from 'components/SocialDistancing'
import { SmallInput } from 'components/FormElements/FormInput'
import { StyledErrorMessage } from 'components/FormElements/ErrorMessage'
import { getDatesKey, useSavedObject } from './useLocalStorage'

const Container = styled.div`
  position: relative;
  display: flex;
`

const Menu = styled.div`
  position: absolute;
  z-index: 999;
  min-width: 100%;
  right: 0;
  margin: 8px;
  padding: 24px 0 8px 0;
  background: ${Color.White};
  box-shadow: 0px 1px 2px rgba(22, 42, 56, 0.3);
  border-radius: 2px;
`

const CustomSection = styled.div`
  display: flex;
  padding: 12px;
`

const InputLabel = styled.label`
  font-weight: bold;
  font-size: 10px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${Color.Black60};
`

const Input = styled(SmallInput)`
  border-radius: 2px;
`

const Option = styled.option`
  cursor: pointer;
`

const Select = styled(BaseSelect)`
  flex-grow: 1;
  min-width: 230px;
  text-align: left;
  background-color: ${Color.White};
`

const DateRanges: { [key: string]: string } = {
  [DateRange.Today]: intlMessageForId('Charts.Filters.DateRange.Today'),
  [DateRange.Yesterday]: intlMessageForId('Charts.Filters.DateRange.Yesterday'),
  [DateRange.Week]: intlMessageForId('Charts.Filters.DateRange.Past7Days'),
  [DateRange.Month]: intlMessageForId('Charts.Filters.DateRange.Past30Days'),
  [DateRange.Year]: intlMessageForId('Charts.Filters.DateRange.Past365Days'),
  [DateRange.Custom]: intlMessageForId('Charts.Filters.DateRange.Custom'),
}

const DATE_FORMAT = 'YYYY-MM-DD'

// week to date
const initialCustomStartDate = dayjs().startOf('week').format(DATE_FORMAT) // sunday
const initialCustomEndDate = dayjs().format(DATE_FORMAT) // today

const useCustomDateFilter: (
  id: string,
  initialDateRange?: DateRange
) => {
  Component: FunctionComponent
  dateRange: DateRange
  startDate: ISODate
  endDate: ISODate
  startTime: ISODateTime
  endTime: ISODateTime
  key: string
} = (id, initialDateRange = DateRange.Week) => {
  const startDateInputRef = useRef(null)
  const endDateInputRef = useRef(null)

  const [savedDates, setSavedDates] = useSavedObject(getDatesKey(id))

  const [showMenu, setShowMenu] = useState<boolean>(false)

  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>(
    savedDates.selectedDateRange || initialDateRange
  )

  const [previousDateRange, setPreviousDateRange] = useState<DateRange>(
    savedDates.previousDateRange || initialDateRange
  )

  const [customStartDate, setCustomStartDate] = useState<ISODate>(
    savedDates.customStartDate || initialCustomStartDate
  )
  const [customEndDate, setCustomEndDate] = useState<ISODate>(
    savedDates.customEndDate || initialCustomEndDate
  )

  const [error, setError] = useState<string>()

  // clear error on select
  useEffect(() => {
    setError(undefined)
  }, [selectedDateRange])

  // close filter on select unless DateRange.Custom is selected
  useEffect(() => {
    if (selectedDateRange !== DateRange.Custom) {
      setShowMenu(false)
    }
  }, [selectedDateRange])

  useEffect(() => {
    setSavedDates({
      selectedDateRange,
      previousDateRange,
      customStartDate,
      customEndDate,
    })
  }, [
    setSavedDates,
    selectedDateRange,
    previousDateRange,
    customStartDate,
    customEndDate,
  ])

  const now = new Date()
  const dateRange = getDateRanges(now)(selectedDateRange)

  const values: { startDate: ISODate; endDate: ISODate; display: string } =
    selectedDateRange === DateRange.Custom
      ? {
          startDate: customStartDate,
          endDate: customEndDate,
          display: `${toLocaleDate(customStartDate)} - ${toLocaleDate(
            customEndDate
          )}`,
        }
      : {
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          display: DateRanges[selectedDateRange],
        }

  const inputProps = { type: 'date', max: toIsoDate(now) }

  const selectOptions: () => JSX.Element[] = () =>
    map((key: string) => (
      <Option
        style={
          key === selectedDateRange
            ? {
                padding: '8px 12px',
                color: '#176C8C',
                background: Color.SelectedBackground,
              }
            : { padding: '4px 12px' }
        }
        key={key}
        value={key}
        onClick={(e: AnyObject) => {
          const selected = e.target.value

          trackEvent(EventCategory.Filter, EventAction.Select, selected)

          if (selectedDateRange !== DateRange.Custom) {
            setPreviousDateRange(selectedDateRange)
          }

          setSelectedDateRange(selected)
        }}
      >
        {DateRanges[key]}
      </Option>
    ))(Object.keys(DateRanges))

  const CustomDateFilter: FunctionComponent = () => (
    <ClickAwayListener onClickAway={() => setShowMenu(false)}>
      <Container className="CustomDateFilter">
        <Select as="button" onClick={() => setShowMenu(true)}>
          {values.display}
        </Select>
        {showMenu && (
          <Menu>
            <div>{selectOptions()}</div>
            {selectedDateRange === DateRange.Custom && (
              <div>
                <CustomSection>
                  <SocialDistancing
                    spacing="9px"
                    direction={LayoutDirection.Horizontal}
                  >
                    <div>
                      <SocialDistancing spacing="8px">
                        <InputLabel>
                          {intlMessageForId(
                            'Charts.Filters.DateRange.Custom.Labels.Start'
                          )}
                        </InputLabel>
                        <Input
                          {...inputProps}
                          ref={startDateInputRef}
                          defaultValue={customStartDate}
                        />
                        <SmallSecondaryButton
                          onClick={() => {
                            setSelectedDateRange(previousDateRange)
                            setCustomStartDate(initialCustomStartDate)
                            setCustomEndDate(initialCustomEndDate)
                          }}
                        >
                          {intlMessageForId(
                            'Charts.Filters.DateRange.Custom.Buttons.Cancel'
                          )}
                        </SmallSecondaryButton>
                      </SocialDistancing>
                    </div>
                    <div>
                      <SocialDistancing spacing="8px">
                        <InputLabel>
                          {intlMessageForId(
                            'Charts.Filters.DateRange.Custom.Labels.End'
                          )}
                        </InputLabel>
                        <Input
                          {...inputProps}
                          ref={endDateInputRef}
                          defaultValue={customEndDate}
                        />
                        <SmallPrimaryButton
                          onClick={() => {
                            const start = deconstructFormElementRef(
                              startDateInputRef
                            )

                            const end = deconstructFormElementRef(
                              endDateInputRef
                            )

                            if (!start.valid) {
                              setError(start.message)
                              return
                            }

                            if (!end.valid) {
                              setError(end.message)
                              return
                            }

                            setCustomStartDate(start.value)
                            setCustomEndDate(end.value)

                            if (!isValidDateRange(start.value, end.value)) {
                              setError(
                                intlMessageForId(
                                  'Charts.Filters.DateRange.Custom.ErrorMessage'
                                )
                              )
                              return
                            }

                            trackEvent(
                              EventCategory.Filter,
                              EventAction.Select,
                              `${start}__${end}`
                            )

                            setError(undefined)
                            setShowMenu(false)
                          }}
                        >
                          {intlMessageForId(
                            'Charts.Filters.DateRange.Custom.Buttons.Apply'
                          )}
                        </SmallPrimaryButton>
                      </SocialDistancing>
                    </div>
                  </SocialDistancing>
                </CustomSection>
                {error && (
                  <StyledErrorMessage style={{ padding: '0 12px' }}>
                    {error}
                  </StyledErrorMessage>
                )}
              </div>
            )}
          </Menu>
        )}
      </Container>
    </ClickAwayListener>
  )

  return {
    Component: CustomDateFilter,
    dateRange: selectedDateRange,
    startDate: values.startDate,
    endDate: values.endDate,
    startTime: toIsoDateTime(values.startDate),
    endTime: toIsoDateTime(values.endDate, {
      hour: 23,
      minute: 59,
      second: 59,
    }),
    key: `${id}-date-range`,
  }
}

export default useCustomDateFilter
