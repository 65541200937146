import React, { FunctionComponent, ReactElement } from 'react'
import { Serie } from '@nivo/scatterplot'
import { BarDatum } from '@nivo/bar'
import { useQuery, DocumentNode, gql } from '@apollo/client'
import { includes } from 'lodash'
import ChartLayoutManager from 'components/charts/ChartLayoutManager'
import Scatter from 'components/charts/Scatter'
import DivergingBar from 'components/charts/DivergingBar'
import {
  ConcentrationOverview as Overview,
  QualitativeChartsOverview,
} from 'components/charts/Overview'
import { useThreshold } from 'components/charts/Threshold'
import { FormattedMessageId } from 'types'
import { EventCategory } from 'utils/analytics'
import useCurrentUser from 'hooks/useCurrentUser'
import { Facets } from 'components/charts/FacetsProvider'
import useCustomDateFilter from 'components/charts/hooks/useCustomDateFilter'
import { useFilterFactory } from 'components/charts/hooks/useFilterFactory'
import { getQueryValue, getTakeover } from 'components/charts/utils'
import useLoadAcceptanceSelector, {
  getLoadAcceptanceSelectorQueryValue,
} from 'components/charts/hooks/useLoadAcceptanceSelector'
import AverageTypeSelector from 'components/charts/AverageTypeSelector'
import useResultTypeSelector, {
  ResultTypeSelectorOption,
} from 'components/charts/hooks/useResultTypeSelector'
import { parseProps } from './utils'

export const QUICKSCAN_LOAD_CONCENTRATION_QUERY: DocumentNode = gql`
  query(
    $commodity: String!
    $analyte: String!
    $startTime: DateTime!
    $endTime: DateTime!
    $location: String
    $supplier: String
    $accepted: Boolean
    $resultType: Int
  ) {
    quickscanLoadConcentration(
      commodity: $commodity
      analyte: $analyte
      startTime: $startTime
      endTime: $endTime
      location: $location
      supplier: $supplier
      accepted: $accepted
      resultType: $resultType
    ) {
      measurements {
        analyte
        id
        recordedAt
        resultNumber
        resultText
        sampleId
        unit
        location
        supplier
        accepted
        resultQualIndex
      }
      weightedAverage {
        value
        unit
      }
      average {
        value
        unit
      }
      hasQualResults
      hasQuantResults
    }
  }
`

const QUALITATIVE_ONLY_ANALYTES = ['EN: Enogen']
const QUANTITATIVE_AND_QUALITATIVE_ANALYTES = [
  'AF: Aflatoxin',
  'AF: Aflatoxina',
]

// TODO: add to intl so this works with other languages
const SPROUT_DAMAGE_ANALYTE = 'SD: Sprout Damage Detection'
const highValuesFavorableAnalytes = [SPROUT_DAMAGE_ANALYTE]

const ConcernConcFactory: FunctionComponent<{
  facets: Facets
  titleId: FormattedMessageId
  testId?: string
}> = ({ facets, titleId, testId }) => {
  const {
    Component: CustomDateRange,
    dateRange,
    startTime,
    endTime,
    key,
  } = useCustomDateFilter(titleId)

  const { Filters, selectedFacets } = useFilterFactory(titleId, facets)
  const additionalFilters = [<CustomDateRange key={key} />]

  const {
    LoadAcceptanceSelector,
    selectedLoadAcceptance,
  } = useLoadAcceptanceSelector(EventCategory.Overview, titleId)

  const { ResultTypeSelector, selectedResultType } = useResultTypeSelector(
    EventCategory.Overview,
    titleId
  )

  const isQualitativeOnlyAnalyte = (analyte: string) =>
    includes(QUALITATIVE_ONLY_ANALYTES, analyte)

  const isQuantitativeAndQualitativeAnalyte = (analyte: string) =>
    includes(QUANTITATIVE_AND_QUALITATIVE_ANALYTES, analyte)

  const isNotQuantitativeAndQualitativeAnalyte = (analyte: string) =>
    !isQuantitativeAndQualitativeAnalyte(analyte)

  const selectedAnalyte = selectedFacets.analyte as string // analyte is NOT multi-select

  const getResultType: () => ResultTypeSelectorOption = () => {
    if (isQualitativeOnlyAnalyte(selectedAnalyte)) {
      return ResultTypeSelectorOption.Qualitative
    }

    if (isQuantitativeAndQualitativeAnalyte(selectedAnalyte)) {
      return selectedResultType
    }

    return ResultTypeSelectorOption.Quantitative
  }

  const getResultTypeQueryValue: () => number = () => {
    return Number(getResultType())
  }

  const isQualitative = getResultType() === ResultTypeSelectorOption.Qualitative

  const { loading, error, data } = useQuery(
    QUICKSCAN_LOAD_CONCENTRATION_QUERY,
    {
      variables: {
        commodity: selectedFacets.commodity,
        analyte: selectedFacets.analyte,
        startTime,
        endTime,
        location: getQueryValue('location', selectedFacets.location),
        supplier: getQueryValue('supplier', selectedFacets.supplier),
        accepted: getLoadAcceptanceSelectorQueryValue(selectedLoadAcceptance),
        resultType: getResultTypeQueryValue(),
      },
      fetchPolicy: 'no-cache',
    }
  )

  const {
    hasResults,
    hasQualResults,
    hasQuantResults,
    formattedData,
    yAxisUnits,
    formattedWeightedAverage,
    formattedAverage,
    formattedBushelsAccepted,
    formattedLoads,
  } = parseProps(
    data,
    selectedLoadAcceptance,
    dateRange,
    startTime,
    endTime,
    isQualitative
  )

  const { Component: Threshold, value: threshold } = useThreshold(
    titleId,
    yAxisUnits
  )

  const { chartData, tooltipData, overviewData } = formattedData

  const lowValuesFavorable = !highValuesFavorableAnalytes.includes(
    selectedFacets.analyte as string // analyte facet is always a string
  )

  const chart: () => ReactElement = () => {
    return (
      <>
        {isQualitative ? (
          <DivergingBar
            data={chartData as Array<BarDatum>}
            tooltipData={tooltipData}
            indexBy="formattedDate"
          />
        ) : (
          <Scatter
            data={chartData as Array<Serie>}
            yAxisUnits={yAxisUnits}
            threshold={threshold}
            startTime={startTime}
            endTime={endTime}
            lowValuesFavorable={lowValuesFavorable}
          />
        )}
      </>
    )
  }

  const filters = () => <Filters additionalFilters={additionalFilters} />

  const overview = () => {
    const showResultTypeSelector = () => {
      if (isNotQuantitativeAndQualitativeAnalyte(selectedAnalyte)) {
        return false
      }

      return selectedResultType === ResultTypeSelectorOption.Quantitative
        ? hasQualResults
        : hasQuantResults
    }

    const resultTypeSelector = showResultTypeSelector()
      ? { ResultTypeSelector }
      : {}

    return isQualitative ? (
      <QualitativeChartsOverview
        LoadAcceptanceSelector={LoadAcceptanceSelector}
        percentNotDetected={overviewData?.percentNotDetected}
        acceptedBushels={formattedBushelsAccepted}
        pieData={overviewData?.pieData}
        {...resultTypeSelector}
      />
    ) : (
      <Overview
        weightedAverage={formattedWeightedAverage}
        average={formattedAverage}
        Threshold={Threshold}
        LoadAcceptanceSelector={LoadAcceptanceSelector}
        AverageTypeSelector={() => (
          <AverageTypeSelector
            eventCategory={EventCategory.Overview}
            id={titleId}
            average={formattedAverage}
            weightedAverage={formattedWeightedAverage}
          />
        )}
        acceptedBushels={formattedBushelsAccepted}
        loads={formattedLoads}
        {...resultTypeSelector}
      />
    )
  }

  const { isQuickscanCustomer } = useCurrentUser()

  // note: the scatterplot's and bar chart's no-result takeover live inside the chart components.
  // the takeover here is only used for loading and error takeovers.
  // allows the user to change radio-button filters in the overview even when there's no chart data.
  const takeover = () =>
    getTakeover(isQuickscanCustomer, loading, error, hasResults, true)

  return (
    <ChartLayoutManager
      chart={chart()}
      filters={filters()}
      overview={overview()}
      takeover={takeover()}
      testId={testId}
    />
  )
}

export default ConcernConcFactory
